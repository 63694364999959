<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'banlist'}">Banlists</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Create</a></li>
    </ol>
    <h1 class="page-header">Banlists</h1>
    <panel noButton="true" title="Banlists" variant="panel-default">
      <div class="row mb-3">
        <div class="input-group col-md-4">
          <input
              v-model="search"
              class="form-control py-2 border-right-0 border"
              placeholder="Search ..."
              type="search"
          >
          <span class="input-group-append">
          <button
              class="btn btn-outline-secondary border-left-0 border"
              type="button"
          >
            <i class="fa fa-search" />
          </button>
        </span>
        </div>
        <div class="col-md-8 text-right">
          <router-link
              v-if="$can.view($store.getters.getAuthUser, 'create-banlists')"
              :to="{ name: 'banlist.create'}"
              class="btn btn-primary btn-sm"
          >
            <i class="fa fa-plus"></i> Create New
          </router-link>
          <button
              v-if="$can.view($store.getters.getAuthUser, 'create-banlists')"
              class="btn btn-sm btn-info ml-2"
              type="button"
              @click.prevent="importModal()"
          >
            <i
                class="fa fa-file-excel"
            /> Import Excel
          </button>
        </div>
      </div>
      <loading v-if="loading" />
      <table
          v-else
          class="table table-striped"
      >
        <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Alias</th>
          <th>Identifier</th>
          <th>Identity</th>
          <th>Banlist</th>
          <th>Type</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, i) in banlists"
            :key="i"
        >
          <td>{{ pagination.from + i }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.alias.join(',') }}</td>
          <td>{{ formatIdentity(item.identifier) }}</td>
          <td>{{ item.identity }}</td>
          <td>{{ item.banlist }}</td>
          <td>{{ item.type }}</td>
          <td>
            <button
                class="btn btn-sm btn-info"
                title="edit"
                type="button"
                @click.prevent="editBan(item)"
            >
              <i
                  class="fa fa-edit"
              />
            </button>
            <button
                class="btn btn-sm ml-1 btn-danger"
                title="Delete"
                type="button"
                @click.prevent="deleteBan(item)"
            >
              <i
                  class="fa fa-trash"
              />
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <pagination
          v-if="pagination.last_page > 1"
          :offset="5"
          :pagination="pagination"
          @paginate="fetch"
      />
    </panel>
    <modal
        v-if="ban"
        :show-modal="showModal"
        @close="showModal = false"
    >
      <template v-slot:header>
        <h5>Edit Banlist</h5>
      </template>
      <form
          @submit.prevent="validateForm"
      >
        <div class="row">
          <div class="form-group col-md-6">
            <label>Name</label>
            <input
                v-model="ban.name"
                class="form-control"
                required
                type="text"
            >
            <div :class="{'invalid-feedback': errors.has('name')}">
              {{ errors.first('name') }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Alias</label>
            <textarea
                v-model="ban.alias"
                class="form-control"
                placeholder="Enter comma separated aliases"
                type="text"
            />
            <div :class="{'invalid-feedback': errors.has('alias')}">
              {{ errors.first('alias') }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Identifier</label>
            <select
                v-model="ban.identifier"
                class="form-control"
            >
              <option value="">
                N/A
              </option>
              <option value="id_number">
                ID Number
              </option>
              <option value="passport">
                Passport
              </option>
              <option value="kra_pin">
                KRA PIN
              </option>
            </select>
            <div :class="{'invalid-feedback': errors.has('identifier')}">
              {{ errors.first('identifier') }}
            </div>
          </div>
          <div
              v-if="ban.identifier"
              class="form-group col-md-6"
          >
            <label>Identity </label>
            <input
                v-model="ban.identity"
                class="form-control"
                required
            >
            <div :class="{'invalid-feedback': errors.has('identity')}">
              {{ errors.first('identity') }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Banlist</label>
            <input
                v-model="ban.banlist"
                class="form-control"
                required
            >
            <div :class="{'invalid-feedback': errors.has('banlist')}">
              {{ errors.first('banlist') }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Type</label>
            <select
                v-model="ban.type"
                class="form-control"
            >
              <option value="">
                N/A
              </option>
              <option value="individual">
                Individual
              </option>
              <option value="company">
                Company
              </option>
            </select>
            <div :class="{'invalid-feedback': errors.has('type')}">
              {{ errors.first('type') }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <button
              class="btn btn-primary"
              type="submit"
          >
            Update
          </button>
        </div>
      </form>
    </modal>

    <modal
        :show-modal="showImportModal"
        @close="showImportModal = false"
    >
      <template v-slot:header>
        <h5>Import Banlist Excel</h5>
      </template>
      <div v-html="$error.handle(error)" />
      <form
          @submit.prevent="importFile"
      >
        <div class="form-group">
          <label>Choose Excel File</label>
          <input
              ref="import_file"
              :class="{ 'invalid-feedback' : error.message }"
              accept=".xlsx"
              class="form-control"
              name="file_import"
              required
              type="file"
              @change="onFileChange"
          >
        </div>

        <div class="form-group">
          <button
              v-if="upload"
              class="btn btn-primary"
              type="submit"
          >
            <i class="fa fa-upload"></i> Upload
          </button>
          <button
              v-else
              class="btn btn-warning"
          >
            Loading <i class="fa fa-spinner fa-spin" />
          </button>
          <button
              class="btn btn-success"
              style="margin-left: 5px;"
              type="button"
              @click="downloadFile"
          >
            <i class="fa fa-download"></i> Download Sample
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {

  data() {
    return {
      search: '',
      upload: true,
      loading: true,
      banlists: [],
      showModal: false,
      showImportModal: false,
      ban: {},
      error: {},
      import_file: '',
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    onFileChange(e) {
      this.import_file = e.target.files[0];
    },
    importFile() {
      let formData = new FormData();
      formData.append('import_file', this.import_file);
      this.error = "";
      this.upload = false;
      this.$axios.post('/api/v1/banlist/import', formData, {
        headers: {'content-type': 'multipart/form-data'}
      }).then(() => {
        this.upload = true;
        this.$toastr.s('Ban list imported successfully');
        this.$router.go();
      }).catch(error => {
        this.upload = true;
        this.$toastr.e('An error occured!');
        this.error = error.response;
      });
    },
    downloadFile() {
      this.$axios({
        url: '/api/v1/banlist/download',
        method: 'POST',
        responseType: 'arraybuffer',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'banlist_export.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    formatIdentity(e) {
      if (e === 'id_number') return 'ID Number';
      if (e === 'passport') return 'Passport No';
      if (e === 'kra_pin') return 'KRA PIN';
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2000)
    },
    fetch() {
      this.loading = true;
      this.$axios.get('/api/v1/banlist', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.loading = false;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.banlists = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
    editBan(ban) {
      if (ban.identifier) {
        ban.identifier.trim() === '' ? ban.identifier = null : ban.identifier;
      }
      if (ban.identity) {
        ban.identity.trim() === '' ? ban.identity = null : ban.identity;
      }
      if (ban.type) {
        ban.type.trim() === '' ? ban.type = null : ban.type;
      }
      this.ban = ban;
      this.showModal = true;
    },
    importModal() {
      this.showImportModal = true;
    },
    deleteBan(ban) {
      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the ban item " + ban.name + " ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete(`/api/v1/banlist/${ban.id}`).then(() => {
            this.fetch();
            this.$toastr.s('Ban item deleted');
          }).catch(error => {
            this.$toastr.w(error.response.data.error);
          });
        }
      })
    },

    validateForm() {
      const ban = (({name, identifier, identity, banlist, alias, type}) =>
          ({name, identifier, identity, banlist, alias, type}))(this.ban);

      if (!(ban.alias instanceof Array)) {
        ban.alias.trim() === '' ? ban.alias = [] : ban.alias = ban.alias.split(',');
      }
      this.$axios.patch(`/api/v1/banlist/${this.ban.id}`, ban).then(() => {
        this.$toastr.s("Ban item updated successfully");
        this.showModal = false;
        this.fetch();
      }).catch(err => {
        if (err.response) {
          this.error = err.response;
        }
      });
    },
  }
}
</script>
